// Home.js

import PandaBuySignUpLink from "../PandaBuySignUpLink/PandaBuySignUpLink";
import Hero from "../Home/hero";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/navigation";
import React, { Suspense } from "react";
const Faq = React.lazy(() => import("../Home/Faq"));


const Home = () => {
  return (
    <div>
      <PandaBuySignUpLink />
      <Navigation />
      <Hero />
      <Suspense fallback={<div>Loading FAQ...</div>}>
        <Faq />
      </Suspense>
      <Footer />
    </div>
  );
};

export default Home;
