import React, { useEffect, useState } from 'react';
import Card from './Card';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import OfferPopup from './PopUpOffer';

function PaginatedItems({ itemsPerPage, items }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(0, endOffset);

  const handleScroll = () => {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      const newOffset = itemOffset + itemsPerPage;
      setItemOffset(newOffset);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [itemOffset]);



  return (
    <>
      <div className='cardPage flex flex-wrap container mx-auto pt-8 gap-x-[20px] gap-y-12'>
        {currentItems.map((product, i) => (
          <Card
            key={product.productId || i}
            sugargooAffiliateLink={product.sugargooAffiliateLink}
            cnFansAffiliateLink={product.cnFansAffiliateLink}
            hagoBuyAffiliateLink={product.hagoBuyAffiliateLink}
            superBuyAffiliateLink={product.superBuyAffiliateLink}
            cssBuyAffiliateLink={product.cssBuyAffiliateLink}
            pandaBuyAffiliateLink={product.pandaBuyAffiliateLink}
            qcLink={product.qcLink}
            itemName={product.itemName}
            sales={product.sales}
            weight={product.weight}
            pic_url={product.pic_url}
            dimensions={product.dimensions}
            searchName={product.searchName}
            itemCategory={product.itemCategory}
            price={product.price}
            productId={product.productId}
          />
        ))}
      </div>
    </>
  );
}

const CardList = ({ products, selectedCategory, search }) => {
  if (!products || products.length === 0) {
    console.error('Error: products array is empty or not present', products);
    return <div className='text-center pt-20'>No products available.</div>;
  }

  const itemsPerPage = isMobile ? 10 : 18;
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className='relative'>
      <OfferPopup /> {/* Include the OfferPopup here */}
      <PaginatedItems
        itemsPerPage={itemsPerPage}
        items={products}
        selectedCategory={selectedCategory}
        search={search}
      />
      <div
        className='fixed fixedScrollArrow bottom-2'
        style={{ transform: 'translateX(50%)' }}
      >

        <FontAwesomeIcon icon={faChevronCircleUp} onClick={scrollToTop} className='w-12 h-12 cursor-pointer' alt="Scroll up" style={{color: 'var(--call-to-action-color)'}} />
      </div>
    </div>

  );
};

export default CardList;

