import React from "react";
import { AgentName } from "../../config/config";

const Hero = () => {
  return (
    <div className="container mx-auto mt-14 max-sm:mt-2 max-sm:px-8 px-4 ">
      {" "}
      {/* Minska margin-top och lägg till padding på mobil */}
      <div className="heroHeaders">
        <h1 className="heroTxt text-3xl max-sm:text-2xl">
          {" "}
          {/* Minska textstorlek på mobil */}
          Explore the Web-based 2024 {AgentName} spreadsheet
        </h1>
        <h1 className="heroSubheader text-xl max-sm:text-sm max-sm:pt-2">
          {" "}
          {/* Minska textstorlek på mobil */}
          Discover a selection of over 4000 unique {AgentName.toLowerCase}{" "}
          {AgentName.toLowerCase()} finds with qualitycheck.
        </h1>
        <h1 className="heroSubheader text-xl max-sm:text-sm">
          {" "}
          {/* Minska textstorlek på mobil */}
          Effortlessly filter products by Sales, Price, and Alphabetical order.
        </h1>
        <h1 className="heroSubheader text-xl max-sm:text-sm">
          {" "}
          {/* Minska textstorlek på mobil */}
          Save your favorite items to the watchlist for quick access, easy
          comparison & total prices.
        </h1>
        <div className="pt-4 max-sm:px-4">
          <a href="/products" className="btn6 text-xl max-sm:text-lg">
            {" "}
            {/* Minska textstorlek på mobil */}
            Browse Products
          </a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Hero);
