import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

const Filter = ({ onFilterSelect, setFilter, filter }) => {
  const [selected, setSelected] = useState(filter);

  const onFilterChange = (event) => {
    setFilter(event.target.value);
    setSelected(event.target.value);
    onFilterSelect(event.target.value); // Call the prop callback
  };

  const filters = [
    'Alphabetical Order',
    'Sales Ascending',
    'Sales Descending',
    'Price Ascending',
    'Price Descending',
  ];

  return (
    <div className="container mx-auto">
      <div className="filter xl:w-[1125px] max-lg:w-[700px] max-md:w-[225px] max-sm:w-[335px] h-[30px] max-sm:ml-[7%] max-lg:ml-[4%] relative">
        <select
          style={{
            color: 'var(--text-header-color)',
            background: 'var(--search-placeholder-color)',
            border: '1px solid var(--text-header-color)',
          }}
          value={filter}
          onChange={onFilterChange}
          className="rounded-3xl text-base px-8 py-2 w-full"
        >
          {filters.map((filter) => (
            <option key={filter} value={filter}>
              {filter}
            </option>
          ))}
        </select>
        <FontAwesomeIcon
          icon={faFilter}
          className="absolute right-4 top-3"
          style={{ color: 'var(--text-header-color)' }}
        />
      </div>
    </div>
  );
};

export default Filter;


