import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShirt } from '@fortawesome/free-solid-svg-icons';

const Categories = ({ setCategory, onCategorySelect, category }) => {
  const [selected, setSelected] = useState(category);

  const onCategoryChange = (event) => {
    setCategory(event.target.value);
    setSelected(event.target.value);
    onCategorySelect(event.target.value); // Call the prop callback
  };

  const categories = [
    'All',
    'Shoes',
    'Tshirts',
    'Trousers',
    'Sweaters',
    'Outerwear',
    'Accessories',
    'Miscellaneous',
    'Electronics',
  ];

  return (
    <div className="container mx-auto">
      <div className="filter xl:w-[1125px] max-lg:w-[700px] max-md:w-[225px] max-sm:w-[335px] h-[60px] max-sm:ml-[7%] max-lg:ml-[4%] relative">
        <select
        style={{
          color: 'var(--text-header-color)',
          background: 'var(--search-placeholder-color)',
          border: '1px solid var(--text-header-color)',
        }}
          value={selected}
          onChange={onCategoryChange}
          className="rounded-3xl text-base px-8 py-2 w-full"
          aria-label="Product Categories"
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
        <FontAwesomeIcon
          icon={faShirt}
          className="absolute right-4 top-3"
          style={{ color: 'var(--text-header-color)' }}
          alt="Shirt Icon"
        />
      </div>
    </div>
  );
};

export default Categories;
