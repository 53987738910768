import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from './components/NottFound/NotFound';
import Home from './components/Home/Home';
import Guide from './components/Guide/Guide';
import Watchlist from './components/Products/Watchlist/Watchlist';
import Contact from './components/Contact/Contact';
import Products from './components/Products/Products';
import ProductDetails from './components/Products/ProductDetails';
import {  fetchProductsSuccess, fetchProductsFailure } from './features/productSlice';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Hämta produktdata och uppdatera Redux-statet
    import(`./assets/ProductData/Products.json`)
      .then((module) => {
        const data = module.default;
        dispatch(fetchProductsSuccess(data));
      })
      .catch((error) => {
        console.error(`Error importing product data:`, error);
        dispatch(fetchProductsFailure('Error fetching products'));
      });
  }, [dispatch]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/products/:productName/:productId" element={<ProductDetails />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/watchlist" element={<Watchlist />} />  
          <Route path="/contact" element={<Contact />} />  
          <Route path="/products" element={<Products />} />   
        </Routes>
      </Router>
    </>
  );
}

export default App;
