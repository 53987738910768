import React from 'react';

const OtherForm = () => {
  return (
    <div className='mb-7'>
      <div className='mb-7'>
        <div className='mb-7'>
          <label htmlFor='email' className='block mb-0 text-[0.688rem]' style={{color: 'var(--text-header-color)'}}>
            E-MAIL
          </label>
          <input
            type='email'
            name='email'
            id='email'
            placeholder='johndoe@hotmail.com'
            required
            style={{backgroundColor: 'var(--primary-color)', borderColor: 'var(--text-header-color)', color: 'var(--text-header-color)', placeholder: 'var(--text-header-color)'}}
            className='border-style w-full px-3 py-3  placeholder:text-center border '
          />
        </div>

        <div className='mb-7'>
          <label htmlFor='name' className='block mb-0 text-[0.688rem]' style={{color: 'var(--text-header-color)'}}>
            Name
          </label>
          <input
            type='text'
            name='name'
            id='name'
            placeholder='Your name optional'
            style={{backgroundColor: 'var(--primary-color)', borderColor: 'var(--text-header-color)', color: 'var(--text-header-color)', placeholder: 'var(--text-header-color)'}}
            className='border-style w-full px-3 py-3 placeholder:text-center border'
          />
        </div>

        <div className='mb-7'>
          <label htmlFor='message' className='block mb-0 text-[0.688rem]' style={{color: 'var(--text-header-color)'}}>
            Message
          </label>
          <textarea
            name='message'
            id='message'
            placeholder='Your message'
            required
            style={{backgroundColor: 'var(--primary-color)', borderColor: 'var(--text-header-color)', color: 'var(--text-header-color)', placeholder: 'var(--text-header-color)'}}
            className='border-style w-full px-3 py-3 placeholder:text-center border'
            rows={5}
          />
        </div>

        <div className=''>
          <div class="h-captcha" data-captcha="true"></div>
          <button
            type='submit'
            style={{backgroundColor: 'var(--primary-color)', borderColor: 'var(--text-header-color)', color: 'var(--text-header-color)', placeholder: 'var(--text-header-color)'}}
            className='w-full px-3 py-3 border
                      flex justify-center  text-sm gap-3 hover:border
                      hover:border-[#A259FF] hover:duration-300 cursor-pointer hover:text-white'
          >
            Send
            <img className='pt-1' src="https://res.cloudinary.com/dh1ngw6pq/image/upload/v1700697576/ArrowWhite_sp99ny.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtherForm;

