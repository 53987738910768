// store.js
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import productReducer from '../features/productSlice';
import searchReducer from '../features/searchSlice';
import categoryReducer from '../features/categorySlice';
import filterReducer from '../features/filterSlice';
import watchListReducer from '../features/watchListSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  products: productReducer,
  search: searchReducer,
  category: categoryReducer,
  filter: filterReducer,
  watchlist: watchListReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export { store };
