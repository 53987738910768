// categorySlice.js
import { createSlice } from '@reduxjs/toolkit';

export const categorySlice = createSlice({
  name: 'category',
  initialState: 'All', // Set an initial category
  reducers: {
    setCategory: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCategory } = categorySlice.actions;
export const selectCategory = (state) => state.category;
export default categorySlice.reducer;