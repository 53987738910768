import { createSlice } from '@reduxjs/toolkit';

export const watchListSlice = createSlice({
  name: 'watchlist',
  initialState: [],
  reducers: {
    addToWatchList: (state, action) => {
      return [...state, action.payload];
    },
    removeFromWatchList: (state, action) => {
      const indexToRemove = action.payload;
      return state.filter((item, index) => index !== indexToRemove);
    },
  },
});

export const { addToWatchList, removeFromWatchList } = watchListSlice.actions;
export const selectWatchList = (state) => state.watchlist;
export default watchListSlice.reducer;
