import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import debounce from 'lodash.debounce';

const SearchBox = ({ onSearchSubmit }) => {
  const [searchValue, setSearchValue] = useState('');
  const originalProducts = useSelector((state) => state.products.products);
  const [suggestions, setSuggestions] = useState([]);
  const [formattedValue, setFormattedValue] = useState("");

  // Debounced function to handle input change
  const debouncedHandleChange = useCallback(
    debounce((sanitizedInput) => {
      // Check for empty input
      if (sanitizedInput.trim() === '') {
        setSuggestions([]);
        setFormattedValue("");
        onSearchSubmit("");
        return;
      }

      // Update the formatted value
      setFormattedValue(sanitizedInput.trim().toLowerCase());

      // Filter products for suggestions
      const suggestionResults = originalProducts.filter((product) =>
        product.itemName.toLowerCase().includes(sanitizedInput.trim().toLowerCase())
      );

      // Set the filtered suggestions
      setSuggestions(suggestionResults);
    }, 300), // Debounce delay of 300ms
    [originalProducts, onSearchSubmit]
  , []);

  const handleChange = (e) => {
    const value = e.target.value;
    const sanitizedInput = DOMPurify.sanitize(value);
    setSearchValue(sanitizedInput);
    debouncedHandleChange(sanitizedInput);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion.itemName);
    setSuggestions([]); // Clear suggestions
    onSearchSubmit(suggestion.searchName);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedValue = searchValue.trim();
    if (trimmedValue) {
      setSuggestions([]); // Clear suggestions on submit
      onSearchSubmit(trimmedValue.toLowerCase());
    }
  };

  return (
    <div className="container mx-auto pt-6">
      <form onSubmit={handleSubmit}>
        <div className="searchBox mb-4 relative lg:w-[1125px] max-lg:w-[700px] max-md:pl-20 max-md:w-[225px] max-sm:mb-6 max-sm:w-[335px]">
          <input
            style={{
              color: 'var(--text-header-color)',
              background: 'var(--search-placeholder-color)',
              border: '1px solid var(--text-header-color)',
            }}
            className="searchPlaceHolder lg:w-[1125px] max-lg:w-[700px] max-md:w-[225px] max-sm:w-[335px] px-4"
            type="search"
            placeholder="Search your favorite products"
            value={searchValue}
            onChange={handleChange}
          />
          <button type="submit" className="searchButton">
            <FontAwesomeIcon icon={faSearch} style={{ color: 'var(--text-header-color)' }} className="MagnifyingGlass absolute right-1 top-[30%] pr-4" />
          </button>
        </div>

        {suggestions.length > 0 ? (
          <ul
            style={{ color: 'var(--text-header-color)', background: 'var(--secondary-color)' }}
            className="suggestionList flex flex-col gap-y-2 underline cursor-pointer lg:max-h-[550px] max-sm:max-w-full max-sm:items-center overflow-y-auto"
          >
            {suggestions.map((suggestion) => (
              <li
                className="flex justify-center text-wrap text-center"
                key={suggestion.id || suggestion.itemName}  // Use a fallback key if `id` is missing
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.itemName}
              </li>
            ))}
          </ul>
        ) : (
          searchValue && (  // Show message only if there is a search term
            <p style={{ color: 'var(--text-muted-color)' }} className="text-center italic"></p>
          )
        )}
      </form>
    </div>
  );
};

export default SearchBox;
