import React from 'react'
import { AgentName } from '../../config/config'
const BrokenlinkForm = () => {
  return (
    <div className='mb-7'>
    <div className='mb-7'>
    <label style={{color: 'var(--text-header-color)'}} htmlFor='email' className='block mb-0 text-[0.688rem]'>
      E-MAIL
    </label>
    <input
      type='email'
      name='email'   
      id='email'
      placeholder='johndoe@hotmail.com'
      required
      style={{backgroundColor: 'var(--primary-color)', borderColor: 'var(--text-header-color)', color: 'var(--text-header-color)', placeholder: 'var(--text-header-color)'}}
      className=' w-full px-3 py-3 placeholder:text-center border'
    />
  </div>


  <div className='mb-7'>
    <label style={{color: 'var(--text-header-color)'}} htmlFor='product name' className='block mb-0 text-[0.688rem]'>
      Product Name
    </label>
    <input
      type='text'
      name='productname'   
      id='productname'
      placeholder='e.g Supreme boxlogo #1'
      required
      style={{backgroundColor: 'var(--primary-color)', borderColor: 'var(--text-header-color)', color: 'var(--text-header-color)', placeholder: 'var(--text-header-color)'}}
      className=' w-full px-3 py-3 placeholder:text-center border'
    />
  </div>

  <div className='mb-7'>
    <label style={{color: 'var(--text-header-color)'}} htmlFor='Pandabuy Link' className='block mb-0 text-[0.688rem]'>
      {AgentName} link (If available)
    </label>
    <input
     style={{backgroundColor: 'var(--primary-color)', borderColor: 'var(--text-header-color)', color: 'var(--text-header-color)', placeholder: 'var(--text-header-color)'}}
      type='text'
      name='pandabuylink'   
      id='pandabuylink'
      placeholder={`e.g https://www.${AgentName}.com/`}
      required
      className=' w-full px-3 py-3 placeholder:text-center border '
    />
  </div>

  <div className=''>
    <div class="h-captcha" data-captcha="true"></div>
    <button
     style={{backgroundColor: 'var(--primary-color)', borderColor: 'var(--text-header-color)', color: 'var(--text-header-color)', placeholder: 'var(--text-header-color)'}}
      type='submit'
      className='w-full px-3 py-3 bg-purple border
                flex justify-center text-sm gap-3 hover:bg-purple
                hover:border-[#A259FF] hover:duration-300 cursor-pointer'
    >
      Send
      <img className='pt-1' src="https://res.cloudinary.com/dh1ngw6pq/image/upload/v1700697576/ArrowWhite_sp99ny.svg" alt="" />
    </button>
  </div>
</div>
  )
}

export default BrokenlinkForm
